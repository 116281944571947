/**
 * Utility to handle BigInt serialization in JSON
 *
 * This file adds a toJSON method to the BigInt prototype
 * to properly serialize BigInt values when using JSON.stringify()
 */

// Add toJSON method to BigInt prototype to convert to string
// This prevents "TypeError: Do not know how to serialize a BigInt" errors
(BigInt.prototype as any).toJSON = function () {
  return this.toString();
};

/**
 * Custom JSON replacer function for manual serialization if needed
 * @param key - The current key being processed
 * @param value - The value for the current key
 * @returns - The processed value
 */
export function bigIntReplacer(key: string, value: any): any {
  if (typeof value === "bigint") {
    return value.toString();
  }
  return value;
}

/**
 * Custom JSON reviver function for manual deserialization if needed
 * @param key - The current key being processed
 * @param value - The value for the current key
 * @returns - The processed value
 */
export function bigIntReviver(key: string, value: any): any {
  // If the value is a string that looks like a BigInt string marker
  if (typeof value === "string" && /^\d+n$/.test(value)) {
    return BigInt(value.slice(0, -1));
  }
  return value;
}

/**
 * Safe stringify function that handles BigInt values
 * @param obj - Object to stringify
 * @returns - JSON string
 */
export function safeStringify(obj: any): string {
  return JSON.stringify(obj, bigIntReplacer);
}

/**
 * Safe parse function that handles BigInt values
 * @param text - JSON string to parse
 * @returns - Parsed object with BigInt values
 */
export function safeParse(text: string): any {
  return JSON.parse(text, bigIntReviver);
}
