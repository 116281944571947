import { useEffect } from "react";
import { SessionProvider } from "next-auth/react";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import { GoogleAnalytics } from "nextjs-google-analytics";
import { GoogleTagManager } from "@next/third-parties/google";
// import redux requirements
import { Provider } from "react-redux";
import { PersistGate } from "reduxjs-toolkit-persist/integration/react";
import { datadogRum } from "@datadog/browser-rum";
import packageInfo from "../package.json";

// Import BigInt serialization utility
import "../utils/bigint-serializer";

import { store, persistedStore } from "../store/store";
import HtmlHeader from "../components/htmlHeader";
import "../styles/font/CS-Interface/style.css";
import "bootstrap/dist/css/bootstrap.min.css";

// Public site styles
import "../sass/home/default.scss";
//import '../sass/home/style.scss'
import "../sass/home/variables.scss";
import "../sass/home/custom.scss";
import "../sass/home/animate.scss";

import "../styles/vendor/OverlayScrollbars.min.css";
import "../styles/vendor/plyr.css";
import "../styles/styles.css";
import "../sass/styles.scss";

// Kept this in MAIN after merge from curriculum
import Loader from "../components/loader";

const App = ({ Component, pageProps }) => {
  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    site: process.env.NEXT_PUBLIC_DATADOG_SITE,
    service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
    env: process.env.NEXT_PUBLIC_DATADOG_ENV,
    version: packageInfo.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow",
    // Specify URLs to propagate trace headers for connection between RUM and backend trace
    allowedTracingUrls: [
      {
        match: process.env.NEXT_PUBLIC_DATADOG_ALLOWED_TRACING_URLS,
        propagatorTypes: ["tracecontext"],
      },
    ],
  });

  return (
    <Provider store={store}>
      <SessionProvider session={pageProps.session}>
        <PersistGate loading={null} persistor={persistedStore}>
          <HtmlHeader title={"SchoolBox"} />
          <GoogleTagManager gtmId="GTM-NZXN828" />
          {Component.auth ? (
            <Auth roles={Component.roles}>
              <GoogleAnalytics trackPageViews />
              <Component {...pageProps} />
            </Auth>
          ) : (
            <Component {...pageProps} />
          )}
        </PersistGate>
      </SessionProvider>
    </Provider>
  );
};

function Auth({ roles, children }) {
  const router = useRouter();

  const { data: session } = useSession({
    required: true,
    onUnauthenticated() {
      router.replace("/signin");
    },
  });

  useEffect(() => {
    if (session) {
      const isValidUser = !!session?.roles?.some((r) => roles.includes(r));
      if (!isValidUser) {
        router.replace("/");
      }

      if (session.user) {
        datadogRum.setUser({
          id: session.userId || "-",
          name: session.user?.firstName + " " + session.user?.lastName,
          email: session.user?.email || "-",
          roles: session?.roles?.join(","),
        });
      }
    }
  }, [session, router, roles]);

  const isUser = !!session?.user;
  const isValid = !!session?.roles?.some((r) => roles.includes(r));

  if (isUser && isValid) {
    return children;
  }

  return <Loader />;
}

export default App;
